import logo from './logo.svg';
import './App.css';
import { Selection, Loader, Footer, Input } from './containers';
import Placeholder from './assets/placeholder.png';
import Placeholder2 from './assets/placeholder2.png';
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

const TRACKING_ID = "UA-127530477-1"; 

 
const tagManagerArgs = {
    gtmId: 'GTM-T9Q8QNQ'
}
 
TagManager.initialize(tagManagerArgs)


const App = () => {
  const [showFooterTitle, setShowFooterTitle] = useState(false);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  return (
    <div className='background__img'>
       <Loader setShowFooterTitle={setShowFooterTitle} />
      <Footer showFooterTitle={showFooterTitle} />
    </div> 
  )
}

export default App
