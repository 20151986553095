import React, { useState, useRef } from "react";
import ShareModal from '../../containers/sharemodal/ShareModal';
import './textform.css';
import Share from '../../assets/Share.svg'

function Textform() {
  

  return (
    <div>
      {/* <button onClick={handleSharing} className="share__btn" type="submit">
        <img src={Share} alt="Share" />
      </button>

      {showModal ? <ShareModal/> : null} */}
    </div>
  );
}
export default Textform;


