import React, { Fragment } from "react";
import './sharemodal.css'
import FB from '../../assets/FB Share.svg';
import Twit from '../../assets/twitter Share.svg';
import Ig from '../../assets/IG Share.svg';
import Tik from '../../assets/TikTok Share.svg';
import {
    TwitterShareButton,
    FacebookShareButton
  } from "react-share";
  

function ShareModal() {
  return (

             <div className='share__btns'>
              <div className="share__wrap">
                <FacebookShareButton
                  url={"https://johnwick.movie/"}
                  quote={"John Wick: Chapter 4: In theaters March 24!"}
                  hashtag="#johnwick4movie" >
                <a className=''><img src={FB} alt="share on facebook" /></a>
                </FacebookShareButton>
                

                <TwitterShareButton
                  url={"https://johnwick.movie/"}
                  title={"John Wick: Chapter 4: In theaters March 24!"}
                  via="John Wick: Chapter 4"
                  hashtags={["#johnwick4movie", "#johnwickchap4,#johnwickseries"]}
                  className="">
                  <a className=''><img src={Twit} alt="share on twitter" /></a>
                </TwitterShareButton>
                </div>
                </div>

                
  );
}
export default ShareModal;