import React, { useState } from 'react';
import Treatment from '../../assets/johnwick4_title.svg';
import './loader.css';
import Selection from '../../containers/selection/Selection';



const Loader = ({setShowFooterTitle}) => {
  const [showSelection, setShowSelection] = useState(false);
  const [hideFooterTitle, setHideFooterTitle] = useState(false);

  const handleClick = () => {
    setShowSelection(true);
    setShowFooterTitle(true);
  }

  return (
    <div>
      {showSelection ? <Selection /> : 
        <main className='main__container fadeIn'>
            <img className="treatment" src={Treatment} alt='John Wick 4 Title Treatment' />
            <p>Send a Valentine's Day e-card to keep your friends close and your enemies closer.</p>
            <button onClick={handleClick} className='primary__btn'>LET'S BEGIN</button>
        </main> }
    </div>
  )
}

export default Loader