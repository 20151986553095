import React, { useState } from 'react';
import Slider from 'react-slick';
import './selection.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footertt from '../../containers/footertt/Footertt';
import Left from '../../assets/Left Arrow.svg';
import Right from '../../assets/Right Arrow.svg';
import Card0 from '../../assets/JW4_BFF-1_ECard.jpg';
import Card1 from '../../assets/JW4_IAWYB-1_ECard.jpg';
import Card2 from '../../assets/JW4_LIAB_ECard.jpg';
import Card3 from '../../assets/JW4_ILYTTHTAB_ECard.jpg';
import Card4 from '../../assets/JW4_IUATW_ECard.jpg';
import Card5 from '../../assets/JW4_JSH-4_ECard.jpg';
import Card6 from '../../assets/JW4_JHOT-4_ECard.jpg';
import Card7 from '../../assets/JW4_LMTYOT_ECard.jpg';
import Card8 from '../../assets/JW4_MHBTY-2_ECard.jpg';
import Input from '../../containers/input/Input';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slick-arrow"
      style={{ ...style, display: 'block', position: 'absolute', right: 0, top: 135, cursor: 'pointer',}}
      onClick={onClick}
    >
    <img src={Right} alt="Arrow Right"/>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slick-arrowR"
      style={{ ...style, display: 'block', position: 'absolute', zIndex: 1, top: 135, cursor: 'pointer',}}
      onClick={onClick}
    >
     <img src={Left} alt="Arrow Left"/>
     </div>
  );
}


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

function Selection() {
  const [currentImage, setCurrentImage] = useState();
  const images = [
    Card0,
    Card1,
    Card2,
    Card3,
    Card4,
    Card5,
    Card6,
    Card7,
    Card8,
  ];

const [currentSlide, setCurrentSlide] = useState(0);

const handleAfterChange = (current) => {
    setCurrentSlide(current);
}

const [showInput, setShowInput] = useState(false);

function handleCustomizeButtonClick() {
  setCurrentImage(images[currentSlide]);
  setShowInput(true);
  // Save the current selected image to local storage
  localStorage.setItem('currentImage', images[currentSlide]);
  console.log(images[currentSlide]);

}




  return (
    <div>
      {showInput ? <Input /> : 
      <main className='main__container fadeIn'>
      <p className='topP'><strong>NO VALENTINE? THESE CARDS WILL SET YOU FREE.</strong><br></br>
Choose your e-card. (swipe or tap through options below)</p>
     <Slider {...settings} afterChange={handleAfterChange}>
        {images.map((image, index) => (
          <div style={{maxWidth: '300px', maxHeight: '300px'}}>
            <img
              style={{maxWidth: '300px'}}
              key={index}
              src={image}
              alt=""
            
            />
           </div>
        ))}
      </Slider>
      <div className='btn__wrap'>
      <button className='primary__btn wide__btn' onClick={handleCustomizeButtonClick}>CUSTOMIZE</button>
      </div>
      </main> }
    
      {/* <Footertt />  */}
    </div> 
  );
}

export default Selection;