import React, {useState, useEffect, useRef} from 'react';
import saveAs from 'file-saver';
import {Stage, Layer, Image, Text} from 'react-konva';
import useImage from "use-image";
import './input.css';
import LeftSmall from '../../assets/leftsmallarrow.svg';
import words from 'profane-words'
import Share from '../../assets/Share.svg'
import Selection from '../../containers/selection/Selection';
import ShareModal from '../../containers/sharemodal/ShareModal';
import {swears} from '../data.js'
// import Textform from '../../containers/textform/Textform';
import Reset from '../../assets/restart.svg';
import FB from '../../assets/FB Share.svg';
import IG from '../../assets/IG Share.svg';
import Twit from '../../assets/twitter Share.svg';
import {
  TwitterShareButton,
  FacebookShareButton
} from "react-share";

import ReactGA from 'react-ga';

const Input = ({title, uri}) => {
    const [isRendered, setIsRendered] = useState(false);
    const [text, setText] = useState('');
    const [counter, setCounter] = useState(0);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [dragging, setDragging] = useState(false);





    const handleChange = (e) => {
        setText(e.target.value);
        setCounter(e.target.value.length);
    };

    const handleSubmit = (e) => {
    e.preventDefault();
        console.log(text);
    }

    const handleMouseDown = (e) => {
        setDragging(true);
        setX(e.clientX);
        setY(e.clientY);
    }

    const handleMouseMove = (e) => {
        if (dragging) {
            setX(e.clientX);
            setY(e.clientY);
        }
    }

    const handleMouseUp = (e) => {
        setDragging(false);
    }

    const [image] = useImage(localStorage.getItem("currentImage"));

    const [showSelection, setShowSelection] = useState(false);
    const [showFinal, setShowFinal] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [show, setShow] = useState(true);
    const [isActive, setIsActive] = useState(false);

    function handleBackClick() {
        setShowSelection(true);
    }

    // function handleConfirm() {
    //   const foundSwears = swears.filter(word => text.toLowerCase().includes(word.toLowerCase()));
    //   if(foundSwears.length){
    //     alert('Please remove profanity and try again');
    //     setHidden(!true);
    //     setShow(!false);
    //     setIsActive(false);
    // } else {
    //     console.log('No bad word found');
    //     console.log(text)
    //     setHidden(true);
    //     setShow(false);
    //     setIsActive(!false);
    //     ReactGA.event({
    //       category: 'Button',
    //       action: 'Click',
    //       label: 'Confirm'
    //     });
    // }

    // }


    function handleConfirm() {
      let foundSwears = [];
      for (let i = 0; i < swears.length; i++) {
        let swear = swears[i];
        let regex = new RegExp(`\\b${swear.toLowerCase()}\\b`);
        if (regex.test(text.toLowerCase())) {
          foundSwears.push(swear);
        }
      }
    
      if (foundSwears.length) {
        alert('Please remove profanity and try again');
        setHidden(!true);
        setShow(!false);
        setIsActive(false);
      } else {
        console.log('No bad word found');
        console.log(text);
        setHidden(true);
        setShow(false);
        setIsActive(!false);
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: 'Confirm',
        });
      }
    }


    const stageRef = React.useRef(null);


    function downloadURI(uri, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    
    const handleDownload = () => {
      const uri = stageRef.current.toDataURL({ pixelRatio: 2 });
      console.log(uri);
    
      // Convert the data URL into a binary string
      const binaryString = atob(uri.split(',')[1]);
    
      // Create an array of bytes from the binary string
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
    
      // Create a new Blob with the image data and the specified MIME type
      const blob = new Blob([bytes], { type: 'image/png' });
    
      // Create a new File object with the Blob and the specified file name
      const fileToSave = new File([blob], 'johnwick.png');
    
      // Save the file to the user's device
      saveAs(fileToSave);
    };
    




    const [imageData, setImageData] = useState(null);

    useEffect(() => {
        const uri = stageRef.current.toDataURL({ pixelRatio: 2 });
        setImageData(uri);
      }, []);
      


    const [showModal, setShowModal] = useState(false);

    const canonical = document.querySelector("link[rel=canonical]");
    let url = canonical ? canonical.href : document.location.href;
    const shareDetails = { url, title, text, };

    const [shareData, setShareData] = useState({
        title: "John Wick: Chapter 4 Valentine's Day E-Cards",
        text: "I created a killer card for you.",
        url: "https://johnwick4movie.com",
        files: []
      });


      
const [number, setNumber] = useState("");

// const textBtn = () => {
//   window.location.href = `sms:${number}&body=#JohnWick4 https://ecards.johnwick.movie`;
// };

    return (<div> {
        showSelection ? <Selection /> : 
                <div className='main__container input__container fadeIn'>
        
                  
                    <div id="above" className={isActive ? "active back__btn-container" : "back__btn-container" }>
        
                    {hidden ? <p className='final__copy-above fadeIn'>Let's finish this! <strong>DOWNLOAD AND SHARE</strong> your
                    customized card now.</p> : false }
        
                    {show ? 
                    <button onClick={handleBackClick} className='back__btn fadeIn'><img className="btnIcon" src={LeftSmall} alt="icon"/>Choose A Different Card</button>
                    : true }
                    </div>
                   
                    <Stage id="myCanvas" cursor="pointer" className={isActive ? "active fadeIn" : "" } id="canvas" width={300} height={300} ref={stageRef}>
                        <Layer >
                            <Image width="300" height="300" image={image} />
                            <Text
                              x={150} // center of the stage
                              y={300 - 23} // 300 is the height of the stage and 23 is the fontSize
                            width="239"
                            fontSize="23"
                            fontFamily='eurostile-condensed'
                            lineHeight="1.2"
                            align="center"
                            verticalAlign="bottom"
                            fontStyle="italic bold"
                            shadowColor='black'
                            shadowBlur="4"
                            shadowOffset="{ x: 0, y: 4 }"
                            shadowOpacity ="0.5"
                            fill="white"
                            onMouseEnter={e => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "pointer";
                              }}
                              onMouseLeave={e => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "default";
                              }}
                              text={text} 
                              x={35}
                              y={150 - 23}
                              draggable={true} 
                              onDragMove={(e) => {
                                setX(e.target.x());
                                setY(e.target.y());
                              }}
                              />
                        </Layer>
                    </Stage>
                    
                    { show ?  
                    <div className='fadeIn'> 
                    <p>Enter your personal message below and <strong>DRAG YOUR TEXT</strong> into position.</p>
                    <form onSubmit={handleSubmit}>
                        <textarea placeholder="Write your message here up to 50 characters..." maxLength={50} onChange={handleChange} value={text} />
                        <div>Characters: {counter} / 50</div>
                        <button id="confirm" type="button" onClick={handleConfirm} className="primary__btn wide__btn confirm__btn" disabled={text.length === 0}>CONFIRM</button>
                    </form>
                    </div> : false }       
        
                    { hidden ?        
                    <div className=''>
                        <div> <button className='reset fadeIn' onClick={() => window.location.reload(false)}><img src={Reset} alt="reset" /></button> </div>
                      
                    <div>
                    <button onClick={handleDownload} className="primary__btn wide__btn confirm__btn fadeIn" type="submit">DOWNLOAD</button>
                    </div>
                    
                        
                        <div className='fadeIn'>




                        <div className='share__btns'>
                        <div className="share__wrap">
                          <FacebookShareButton
                            url={" https://ecards.johnwick.movie"}
                            quote={""}
                            hashtag="#JohnWick4" >
                          <a className=''><img src={FB} alt="share on facebook" /></a>
                          </FacebookShareButton>
                          

                          <TwitterShareButton
                            title={""}
                            url={"https://ecards.johnwick.movie"}
                            hashtags={["JohnWick4"]}
                            className="">
                            <a className=''><img src={Twit} alt="share on twitter" /></a>
                          </TwitterShareButton>

                          <a href="https://instagram.com" target="_blank"><img src={IG} alt="share in instagram"/></a>


                          {/* <p className='smsP'>Send a text to a friend or loved one below.</p> */}
  


                            {/* <input
                              id='phone__form'
                              type="text"
                              placeholder=" 000 000 0000"
                              value={number}
                              onChange={(e) => setNumber(e.target.value)}
                            />
                            <button  className="txt" onClick={textBtn}>
                              SEND
                            </button> */}


 
                          </div>
                          </div>
                        {/* <button onClick={handleFirstButtonClick} className="share__btn" type="submit">
                            <img src={Share} alt="Share" />
                        </button> */}
        
                        {/* {showModal ? <ShareModal/> : null} */}

                        <div>

  </div>
                        

                        </div>
                    </div>  
                    : false }       
        
                </div> } 
        
                
                </div>
        )
    }

    export default Input
