import React, { useState } from 'react';
import './footer.css';
import Lg from '../../assets/lionsgate-logo.svg';
import Rating from '../../assets/not-rated.svg';
import Close from '../../assets/close-black.svg';
import Ftitle from '../../assets/footer_tt.svg';
import Dolby from '../../assets/Dolby.svg';
import Imax from '../../assets/Imax.svg';

const Footer = ({ showFooterTitle }) => {

  const [isVisible, setIsVisible] = useState(false);


  const toggleFooter = () => {
    setIsVisible(!isVisible);
  }

    return (<div className='legal__wrap'>
        <div className='footerTT__container'>
          {showFooterTitle && (
          <div className='footer__title fadeIn hide'>
              <img src={Ftitle} alt="John Wick 4 title treatment"/>
          </div>
          )}
          <div>
          <button onClick={toggleFooter} className='legal__btn fadeIn'>LEGAL</button>
          </div>
          
        </div>

        <div className={`footer footer__slideup ${isVisible ? 'slide-in' : 'slide-out'}`}>

        <div className="wrapper">
            <div className="content">
                <div className="top">
                    <div className="left">
                        <img src={Lg} alt="Lionsgate Logo" />
                            <div className="copy">
                                <span className='cc'>®,™ & © 2023 Lions Gate Ent. Inc. All Rights Reserved</span>
                            </div>
                        </div>
                        <div className="right">
                            <div className="buttons">
                              <ul>
                                <li><a href="https://www.lionsgate.com/legal/privacy-policy" target="_blank">PRIVACY NOTICE</a></li>
                                <li><a href="https://www.lionsgate.com/legal/terms-of-use" target="_blank">TERMS OF USE</a></li>
                                <li><a href="https://www.lionsgate.com/legal/do-not-sell-my-information" target="_blank">DO NOT SELL MY INFORMATION</a></li>
                                <li> <a href="https://www.motionpictures.org/" target="_blank">MotionPictures.org</a></li>
                                <li><a href="https://www.filmratings.com/" target="_blank">FilmRatings.com</a></li>                        
                              </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                      <ul>
                        <li><img src={Rating} alt="billing"/></li>
                      <li><img src={Dolby} alt="Dolby"/></li>
                        <li><img src={Imax} alt="Imax"/></li>
                        
                      </ul>
                        
                    </div>
                    <div className="closeButton">
                        <div className="closeLine"></div>
                        <button className='btnfocus'><img onClick={toggleFooter} src={Close} alt="close footer"/></button>
                    </div>
                </div>
                </div>
                </div>
                </div>

                )
                }
                
                export default Footer
